<template>
  <div class="content-info">
    <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700" :text="showText" :is-full-screen="true" />
    <Slider :slides="slides" v-slot="{ currentSlide }">
    <!-- <a :href="currentSlide.link" target="_blank"> -->
        <img :alt="currentSlide.title" :title="currentSlide.title" :src="currentSlide.img">
    <!-- </a> -->
    </Slider>
    <div class="content">
      <div :class="total > params.limit && newsList.length > 0 ? 'showPage content-list' : 'hidPage content-list'">
        <div class="content-item size-12" v-for="(item,key) in newsList" :key="key">
          <!-- <router-link :to=""> -->
          <div class="item-right">{{ item.createTime.substring(0,10) }}</div>
          {{ item.newsTitle }}
          <!-- </router-link> -->
          <div style="clear:both;"></div>
        </div>
      </div>
      <div v-show="newsList.length==0" class="noContent size-16">暂无记录</div>
       <div class="pagination-content">
        <Pagination :currentPage="currentPage" :total="total" :pageSize="params.limit" :onPageChange="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
import { getSearchList } from "@/api/news/index";

export default {
  name: 'Search',
  computed: {
    // 当前页，从查询参数 page 返回
    currentPage() {
      return parseInt(this.$route.query.page) || 1
    }
  },
  data() {
    return {
      slides: [
        {
          title: '搜索',
          img: require('@/assets/search-main.png'),
        },
      ],
      params:{
        page:1,
        limit:10,
        keyWord:''
      },
      newsList:[],
      total: 0, 
    }
  },
  created() {
    this.params.keyWord= this.$route.query.keyWord
    this.params.page = this.$route.query.page || 1;
    this.init();
  },
  watch: {
	// 监听路由是否变化
	'$route' (to) { 
      if(to.query.page != this.params.page || to.query.keyWord != this.params.keyWord ){
        this.params.page = to.query.page || 1;// 把最新id赋值给定义在data中的id
        this.params.keyWord= to.query.keyWord;
        this.init(); // 重新调用加载数据方法
      }
    },
  },
  methods: {
    changePage(page) {
      this.$router.push({ query: { ...this.$route.query, page } })
    },
    init() {
      this.getSearchList()
    },
    getSearchList() {
      this.show = true;
      this.showText = "加载中...";
      this.classifyList = []
      getSearchList(this.params)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            this.newsList = res.data
            this.total = res.count
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    }
  }
}
</script>

<style scoped>
  .content-info {
  }

  .content {
    padding:0 10%;
    min-height:50vh;
  }

  .content-list {
    padding: 20px 10px;

  }

  .hidPage {
    margin:0;
  }

  .showPage{
    margin:40px 0px 0px 0px;
  }
  .noContent {
    height:50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-item {
    padding:10px 0px;
    border-bottom:1px solid #e5e5e5;
  }

  .content-item:hover{
     color: #009dff;
     cursor: pointer;
  }

  .content-item .item-left {
    /* display: inline-block; */
    /* width:80%; */
    /* float: left; */
    /* overflow: hidden; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
  }

  .content-item .item-right {
    /* width:20%; */
    display: inline-block;
    float: right;
    text-align: right;
    padding-left:20px;
  }

  @media (max-width: 1024px) {

  }

  @media (max-width: 768px) {

  }
</style>